import { faHouseUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"

import { Link } from "gatsby"
import Seo from "../components/seo"

const Lend = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="不動産の貸し出し"
        description="株式会社ファーストの不動産貸し出し"
      />
      <div className="container">
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "gray",
            margin: "10px 0px",
          }}
        >
          <FontAwesomeIcon icon={faHouseUser} />
        </div>
        <h3 className="title is-4" style={{ color: "gray" }}>
          不動産の貸し出し
        </h3>
        <section className="section">
          <h4 className="title is-4">賃貸運営をフルサポート</h4>
          <p className="block">
            オーナー様が所有されているアパート・マンション・戸建・分譲マンション等の、入居者の募集、契約手続き、家賃の回収・送金、入居中の対応等の賃貸運営にかかわる様々な業務をまとめてお引き受け致します。
          </p>
          <div className="columns is-centered">
            <div className="column is-three-quarters">
              <StaticImage
                src="../images/flows/flow-lend.drawio.png"
                style={{ margin: "0 auto" }}
                placeholder="none"
                quality={90}
                alt=""
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>賃料査定</span>
                  <ul>
                    <li>市場の需要と周辺地域の調査により、物件を査定します</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>提案・契約</span>
                  <ul>
                    <li>
                      査定した内容を元に、入居者の募集条件をオーナー様へご提案します
                    </li>
                    <li>
                      当社に委託する管理内容をすり合わせ、契約を締結します
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>入居者募集</span>
                  <ul>
                    <li>
                      物件の広告宣伝、入居希望者の問い合わせ対応、現地案内を行います
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>入居者管理</span>
                  <ul>
                    <li>
                      入居時の審査および契約の締結、契約更新やトラブル対応を行います
                    </li>
                    <li>また、家賃回収や退去手続きも行います</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>建物管理</span>
                  <ul>
                    <li>
                      各種法定点検や検針、退去後の清掃や修繕などを行います
                    </li>
                    <li>また建物管理のための書類や鍵の保管も行います</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <Link
            to="/contact"
            className="button is-dark is-fullwidth my-4 is-size-5"
            style={{ height: "4em" }}
          >
            お問い合わせはこちら　＞
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default Lend
